import react from "react";
import tw from "twin.macro";
import Lottie from "react-lottie";
import LoadingIndicatorLight from "../../assets/animations/LoadingIndicatorLight.json";
import LoadingIndicatorPrimary from "../../assets/animations/LoadingIndicatorPrimary.json";

const PrimaryAction = tw.button`px-4 h-12 text-lg  sm:px-8 font-bold bg-primary-default text-white rounded shadow-lg border-0 justify-center items-center`;

const SecondaryAction = tw.button`px-4 h-12 text-lg  sm:px-8 bg-white text-primary-default border-solid border  border-primary-default rounded justify-center items-center`;

const DangerAction = tw.button`px-4 h-12 text-lg  sm:px-8 bg-red-default text-white rounded shadow border-0 justify-center items-center`;

export default function CustomButton({
  title,
  onClick,
  to,
  className,
  buttonType = "primary" | "secondary" | "danger",
  loading = false,
  disabled = false,
}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData:
      buttonType === "primary"
        ? LoadingIndicatorLight
        : LoadingIndicatorPrimary,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  function renderLightLoadingIndicator() {
    return <Lottie options={defaultOptions} width={50} height={25} />;
  }

  function getButtonBackground() {
    if (disabled) {
      return "#F3F3F3";
    }
  }

  function getTextColor() {
    if (disabled) {
      return "#8E8E93";
    }
  }

  function getTitle() {
    return (
      <p className="mb-0" style={{ color: getTextColor() }}>
        {title}
      </p>
    );
  }

  function renderPrimaryButton() {
    return (
      <PrimaryAction
        onClick={onClick}
        to={to}
        className={className}
        style={{ backgroundColor: getButtonBackground() }}
        disabled={disabled}
      >
        {!loading ? getTitle() : renderLightLoadingIndicator()}
      </PrimaryAction>
    );
  }

  function renderSecondaryButton() {
    return (
      <SecondaryAction
        onClick={onClick}
        to={to}
        className={className}
        style={{ backgroundColor: getButtonBackground() }}
        disabled={disabled}
      >
        {getTitle()}
      </SecondaryAction>
    );
  }

  function renderDangerButton() {
    return (
      <DangerAction
        onClick={onClick}
        to={to}
        className={className}
        style={{ backgroundColor: getButtonBackground() }}
        disabled={disabled}
      >
        {getTitle()}
      </DangerAction>
    );
  }

  function renderButton() {
    switch (buttonType) {
      case "secondary":
        return renderSecondaryButton();
      case "danger":
        return renderDangerButton();
      default:
        return renderPrimaryButton();
    }
  }

  return renderButton();
}
