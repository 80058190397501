import React from "react";
import styled from "styled-components"; //eslint-disable-line
import tw from "twin.macro";
import { useTranslation } from "react-i18next";
import GetInTouch from "assets/Images/GetInTouch.jpg";
import { SectionHeading } from "components/misc/Headings.js";

const Container = styled.div`
  ${tw`relative lg:mx-8 mx-2 lg:px-8 px-4 lg:py-16 py-8  mb-8 rounded-lg bg-center bg-cover border`}
  background-image: url(${GetInTouch});
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-50 rounded-lg`;
const Content = tw.div`z-20 relative flex flex-col justify-center items-center`;
const Heading = tw(
  SectionHeading
)`font-black text-white text-center text-3xl sm:text-4xl lg:text-5xl leading-tight mb-4 lg:px-8 px-2`;
const HighlightedText = tw(
  Heading
)`text-white bg-primary-default px-4 py-2 rounded-tl-3xl rounded-br-3xl transform -skew-x-6`;
const Description = tw.p`mt-2 text-white text-center text-sm md:text-base lg:text-lg font-medium mb-2`;

const PrimaryAction = tw.a`no-underline rounded mt-4 px-8 py-3 text-lg sm:px-8 sm:py-4 font-bold shadow transition duration-300 bg-primary-default text-white hocus:shadow-lg hocus:text-white`;

export default () => {
  const { t } = useTranslation();
  const TRANSLATION_PATH = "Components:Cta:GetInTouch";
  const heading = t(TRANSLATION_PATH + ".title");
  const headingHighlighted = t(TRANSLATION_PATH + ".highlighted_title");
  const description = t(TRANSLATION_PATH + ".description");
  const buttonTitle = t(TRANSLATION_PATH + ":button.title");
  return (
    <Container>
      <OpacityOverlay />
      <Content>
        <Heading>{heading}</Heading>
        <HighlightedText>{headingHighlighted}</HighlightedText>
        <Description>{description}</Description>
        <PrimaryAction href="contact">{buttonTitle}</PrimaryAction>
      </Content>
    </Container>
  );
};
