import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "components/headers/dark.js";
import Footer from "components/footers/Footer.js";
import ContactUs from "components/forms/ContactUs";

export const Wrapper = tw.div`pt-10 bg-lightWhite`;

export default () => {
  return (
    <>
      <Header />
      <AnimationRevealPage>
        <Wrapper>
          <ContactUs />
        </Wrapper>
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
