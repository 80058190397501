import React from "react";
import tw from "twin.macro";
//eslint-disable-next-line

import AppDashboardPreview from "../../assets/Images/AppDashboardPreview.png";
import BackendApiClient from "services/api/BackendApiClient.js";
import { useTranslation } from "react-i18next";
import { SectionHeading } from "components/misc/Headings.js";
import DashboardIcon from "assets/illustrations/Icons/DashboardIcon";

export default () => {
  const { t } = useTranslation();
  const TRANSLATION_PATH = "Components:Hero:RestaurantIntro";

  const heading = t(TRANSLATION_PATH + ".title");
  const headingHighlighted = t(TRANSLATION_PATH + ".title_highlighted");
  const description = t(TRANSLATION_PATH + ".description");
  const primaryButtonText = t(TRANSLATION_PATH + ":button.title");
  const imageSrc = AppDashboardPreview;

  function getStarted() {
    const domain = BackendApiClient.getDashboardDomain();
    return domain + "/register";
  }

  return (
    <Container>
      <Heading>{heading}</Heading>
      <HighlightedText>{headingHighlighted}</HighlightedText>
      <Paragraph>{description}</Paragraph>

      <PrimaryAction href={getStarted()}>{primaryButtonText}</PrimaryAction>
      <TwoColumn>
        <LeftColumn>
          <IconContainer>
            <DashboardIcon width={"2.5rem"} color={"#69CE8C"} />
          </IconContainer>
          <ColumnHeader>
            {t(TRANSLATION_PATH + ":illustration_explainer.title")}
          </ColumnHeader>
          <Description>
            {t(TRANSLATION_PATH + ":illustration_explainer.description")}
          </Description>
        </LeftColumn>
        <RightColumn>
          <IllustrationContainer>
            <img
              style={{ width: "100%" }}
              src={imageSrc}
              alt="Design Illustration"
            />
          </IllustrationContainer>
        </RightColumn>
      </TwoColumn>
    </Container>
  );
};

const Container = tw.div`relative bg-lightWhite py-8 lg:px-8 px-2 flex flex-col justify-center items-center`;
const Heading = tw(
  SectionHeading
)`font-black text-black text-left text-3xl sm:text-5xl lg:text-6xl text-center leading-tight`;

const HighlightedText = tw(
  Heading
)`text-white bg-primary-default px-4 py-2 rounded-tl-3xl rounded-br-3xl transform -skew-x-6`;

const Paragraph = tw.p`mb-8 text-lg lg:text-xl text-gray-default text-center mt-4`;
const IconContainer = tw.div`mb-4`;
const ColumnHeader = tw.h5`text-base lg:text-lg text-black font-bold lg:text-left text-center`;
const Description = tw.p`text-base lg:text-lg text-gray-default lg:text-left text-center`;

const PrimaryAction = tw.a`mb-4 px-8 py-3 no-underline text-lg sm:px-8 sm:py-4 bg-primary-default text-white font-bold rounded shadow-lg transition duration-300 hocus:text-white  focus:shadow-outline`;

const IllustrationContainer = tw.div`shadow-lg`;

const TwoColumn = tw.div`flex flex-col lg:flex-row justify-end bg-primary-200 mt-6 rounded-lg shadow-lg`;
const LeftColumn = tw.div`relative lg:p-8 p-4 flex-1 flex flex-col text-center max-w-lg mx-auto lg:max-w-none lg:text-left lg:pr-4 lg:justify-start justify-center lg:items-start items-center`;
const RightColumn = tw.div`relative lg:p-8 lg:pb-0 p-4 pt-0 lg:w-8/12 lg:mt-0 flex flex-col justify-center items-center`;
