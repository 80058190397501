import React, { useState, useMemo } from "react";
import tw from "twin.macro";
import { CFormText } from "@coreui/react";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings.js";
import EmailIllustration from "assets/illustrations/EmailIllustration";
import { useTranslation } from "react-i18next";
import SupportTicketsManager from "services/api/SupportTicketsManager";
import FeedbackLabel from "components/label/FeedbackLabel";
import CustomButton from "components/button/CustomButton";
import { useValidator } from "hooks/useValidator";
import { useForceUpdate } from "hooks/useForceUpdate";

export default ({ textOnLeft = true }) => {
  const { t } = useTranslation();
  const TRANSLATION_PATH = "Components:Forms:ContactUs";
  const validation = useValidator();
  const forceUpdate = useForceUpdate();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [restaurantName, setRestaurantName] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState({
    message: "",
    type: "",
  });

  function validateForm(e) {
    e.preventDefault();
    if (validation.allValid()) {
      return createSupportTicket();
    }
    formErrorHandler();
  }

  function formErrorHandler() {
    const error1 = t(TRANSLATION_PATH + ":error_messages.error2");
    validation.showMessages();
    setFeedback({
      message: error1,
      type: "error",
      errors: validation.getErrorMessages(),
    });
    forceUpdate();
  }

  async function createSupportTicket() {
    setLoading(true);
    const { success, response } = await SupportTicketsManager.create({
      type: null,
      email: email,
      company_name: restaurantName,
      name: name,
      text: message,
    });

    setLoading(false);

    if (!success) {
      return errorHandler(response?.response?.data);
    }

    successHandler();
  }

  function successHandler() {
    setFeedback({
      message: t(TRANSLATION_PATH + ":success_messages.success1"),
      type: "success",
    });
    reset();
  }

  function errorHandler(data) {
    setFeedback({
      message: t(TRANSLATION_PATH + ":error_messages.error1"),
      type: "error",
      errors: data?.errors,
    });
  }

  function reset() {
    setEmail("");
    setRestaurantName("");
    setName("");
    setMessage("");
  }

  function getButtonDisabledStatus() {
    if (email && restaurantName && name && message) {
      return false;
    }
    return true;
  }

  const renderFeedbackLabel = useMemo(() => {
    return feedback?.message ? (
      <div style={{ marginTop: 24 }}>
        <FeedbackLabel
          message={feedback?.message}
          type={feedback?.type}
          errors={feedback?.errors}
        />
      </div>
    ) : null;
  }, [feedback]);

  function renderEmail() {
    const title = t(TRANSLATION_PATH + ":email.title");
    const placeholder = t(TRANSLATION_PATH + ":email.placeholder");
    function renderErrorMessage() {
      return (
        <CFormText className="help-block">
          {validation.message(title, email, "required", {
            className: "text-danger",
          })}
        </CFormText>
      );
    }
    return (
      <div>
        <Input
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={placeholder}
          style={{ width: "100%", backgroundColor: "transparent" }}
        />
        {renderErrorMessage()}
      </div>
    );
  }

  function renderName() {
    const title = t(TRANSLATION_PATH + ":name.title");
    const placeholder = t(TRANSLATION_PATH + ":name.placeholder");
    function renderErrorMessage() {
      return (
        <CFormText className="help-block">
          {validation.message(title, name, "required", {
            className: "text-danger",
          })}
        </CFormText>
      );
    }
    return (
      <div>
        <Input
          type="text"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={placeholder}
          style={{ width: "100%", backgroundColor: "transparent" }}
        />
        {renderErrorMessage()}
      </div>
    );
  }

  function renderRestaurantName() {
    const title = t(TRANSLATION_PATH + ":restaurant_name.title");
    const placeholder = t(TRANSLATION_PATH + ":restaurant_name.placeholder");
    function renderErrorMessage() {
      return (
        <CFormText className="help-block">
          {validation.message(title, restaurantName, "required", {
            className: "text-danger",
          })}
        </CFormText>
      );
    }
    return (
      <div>
        <Input
          type="text"
          name="restaurant-name"
          value={restaurantName}
          onChange={(e) => setRestaurantName(e.target.value)}
          placeholder={placeholder}
          style={{ width: "100%", backgroundColor: "transparent" }}
        />
        {renderErrorMessage()}
      </div>
    );
  }

  function renderMessage() {
    const title = t(TRANSLATION_PATH + ":message.title");
    const placeholder = t(TRANSLATION_PATH + ":message.placeholder");
    function renderErrorMessage() {
      return (
        <CFormText className="help-block">
          {validation.message(title, name, "required", {
            className: "text-danger",
          })}
        </CFormText>
      );
    }
    return (
      <div>
        <Textarea
          name="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder={placeholder}
          style={{ width: "100%", backgroundColor: "transparent" }}
        />
        {renderErrorMessage()}
      </div>
    );
  }

  function renderButton() {
    return (
      <div style={{ marginTop: 24 }}>
        <CustomButton
          title={t(TRANSLATION_PATH + ":button.title")}
          onClick={validateForm}
          loading={loading}
          disabled={getButtonDisabledStatus()}
        />
      </div>
    );
  }

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <EmailIllustration width={"80%"} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>{t(TRANSLATION_PATH + ".title")}</Heading>
            <HighlightedText>
              {t(TRANSLATION_PATH + ".title_highlighted")}
            </HighlightedText>

            <Wrapper>
              {renderEmail()}
              {renderName()}
              {renderRestaurantName()}
              {renderMessage()}
              {renderFeedbackLabel}
              {renderButton()}
            </Wrapper>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};

const Container = tw.div`relative lg:px-8 px-2 py-12 bg-lightWhite`;
const TwoColumn = tw.div`flex flex-col lg:flex-row justify-center w-full`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw.div`justify-center items-center flex-1 lg:flex hidden`;
const TextColumn = styled(Column)((props) => [
  tw`flex-1`,
  props.textOnLeft ? tw`lg:order-first` : tw`lg:order-last`,
]);

const TextContent = tw.div`text-center md:text-left`;

const Heading = tw(
  SectionHeading
)`font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center lg:text-left leading-tight`;
const HighlightedText = tw(Heading)`text-primary-default mt-2 inline-block`;

const Wrapper = tw.div`mt-8 lg:mt-10 text-sm flex flex-col lg:pr-24`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-200`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;
