export default {
  title: "Restaurant",
  title_highlighted: "Sales Accelerator",
  description: "The Nr. 1 tech solution to maximize revenue for local restaurants.",
  illustration_explainer: {
    title: "Maximize your restaurant revenue.",
    description:
      "Get the tools that are exclusive to the big chains.",
  },
  button: {
    title: "Start Free Trial",
  },
};
