import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import { SectionHeading } from "components/misc/Headings.js";
import "react-dropdown/style.css";
import BackendApiClient from "services/api/BackendApiClient";
import { useTranslation } from "react-i18next";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import HelperMethods from "services/exports/HelperMethods";

export default () => {
  const { t } = useTranslation();
  const TRANSLATION_PATH = "Components:Features:RestaurantSalesCalculator";
  const [guests, setGuests] = useState(200);
  const [sales, setSales] = useState(4000);

  const heading = t(TRANSLATION_PATH + ".title");
  const headingHighlighted = t(TRANSLATION_PATH + ".title_highlighted");
  const description = t(TRANSLATION_PATH + ".description");

  useEffect(() => {
    calculateSales();
  }, [guests]);

  function calculateSales() {
    setSales(guests * 400);
  }

  function getStarted() {
    const domain = BackendApiClient.getDashboardDomain();
    return domain + "/register";
  }

  function getReturningCostumers() {
    try {
      return (guests * 0.85)?.toFixed(0);
    } catch (error) {}
  }

  function getSales() {
    try {
      return HelperMethods.formatCurrency(guests * 15 * 2);
    } catch (error) {}
  }

  return (
    <>
      <Container>
        <Heading>{heading}</Heading>
        <HighlightedText>{headingHighlighted}</HighlightedText>
        <Description>{description}</Description>
        <HeroContainer>
          <TwoColumn>
            <PrimaryCard>
              <CardHeader>
                {t(TRANSLATION_PATH + ":block:block1.title")}
              </CardHeader>
              <CardDescription>
                {t(TRANSLATION_PATH + ":block:block1.description")}
              </CardDescription>
              <SliderTitle>{guests}</SliderTitle>
              <div
                style={{
                  width: "100%",
                  padding: "10px ",
                }}
              >
                <Slider
                  min={0}
                  max={750}
                  defaultValue={200}
                  value={guests}
                  marks={{ 0: 0, 750: 750 }}
                  onChange={setGuests}
                  trackStyle={{ backgroundColor: "#69CE8C" }}
                />
              </div>
            </PrimaryCard>

            <SecondaryCard>
              <SecondaryCardHeader>
                {t(
                  TRANSLATION_PATH + ":block:block2:returning_customers.title"
                )}
                <SecondaryCardHeaderHighlighted>
                  {t(
                    TRANSLATION_PATH +
                      ":block:block2:returning_customers.customers",
                    { count: getReturningCostumers() }
                  )}
                </SecondaryCardHeaderHighlighted>
                <SecondaryCardHeaderUnHighlight>
                  {t(
                    TRANSLATION_PATH +
                      ":block:block2:returning_customers.description"
                  )}
                </SecondaryCardHeaderUnHighlight>
              </SecondaryCardHeader>
              <SecondaryCardHeader>
                {t(TRANSLATION_PATH + ":block:block2:revenue.title")}
                <SecondaryCardHeaderHighlighted>
                  {t(TRANSLATION_PATH + ":block:block2:revenue.revenue", {
                    revenue: getSales(),
                  })}
                </SecondaryCardHeaderHighlighted>
                <SecondaryCardHeaderUnHighlight>
                  {t(TRANSLATION_PATH + ":block:block2:revenue.description")}
                </SecondaryCardHeaderUnHighlight>
              </SecondaryCardHeader>
              <PrimaryButton as="a" href={getStarted()}>
                {t(TRANSLATION_PATH + ":button.title")}
              </PrimaryButton>
            </SecondaryCard>
          </TwoColumn>
        </HeroContainer>
      </Container>
    </>
  );
};

const Container = tw.div`relative bg-lightWhite py-8 lg:px-8 px-2 flex flex-col justify-center items-center`;

const HeroContainer = tw.div`z-20 relative w-full`;

const TwoColumn = tw.div`mt-8 flex justify-center items-center lg:flex-row flex-col`;

const Heading = tw(
  SectionHeading
)`font-black text-black text-3xl sm:text-4xl lg:text-5xl text-center leading-tight`;
const HighlightedText = tw(
  Heading
)`text-white bg-primary-default px-4 py-2 rounded-tl-3xl rounded-br-3xl transform -skew-x-6`;
const Description = tw.p`mt-2 text-center text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-default`;

const PrimaryCard = tw.div`lg:w-6/12 w-full rounded-lg bg-white shadow-2xl lg:items-start justify-center items-center px-8 lg:py-16 py-8 lg:mr-6`;
const SecondaryCard = tw.div`lg:flex-1 w-full rounded-lg bg-black shadow-2xl lg:items-start justify-center items-center px-8 lg:py-16 py-8 mt-4 lg:mt-0 flex flex-col`;
const CardHeader = tw.p`text-center lg:text-left text-sm md:text-base lg:text-lg font-bold leading-relaxed text-black mb-2`;
const SecondaryCardHeader = tw.p`text-center lg:text-left text-sm md:text-base lg:text-lg font-bold leading-relaxed text-white mb-2`;
const SecondaryCardHeaderHighlighted = tw.p`inline-block text-primary-default text-lg md:text-xl lg:text-2xl `;
const SecondaryCardHeaderUnHighlight = tw.p`inline-block font-medium`;
const CardDescription = tw.p`text-center lg:text-left text-sm md:text-base lg:text-lg leading-relaxed text-gray-default`;
const SliderTitle = tw.p`font-bold text-primary-default mt-2`;
const MenuTextHighligted = tw.p`mt-4 text-center md:text-left text-2xl sm:text-3xl lg:text-4xl font-bold leading-relaxed text-primary-default`;

const PrimaryButton = tw.a`inline-block no-underline mt-4 px-8 py-3 text-lg sm:px-8 sm:py-4 bg-primary-default text-white hocus:text-white font-bold rounded shadow-lg transition duration-300`;
