import React from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import Logo from "assets/illustrations/Logo.js";
import BackendApiClient from "services/api/BackendApiClient.js";
import { useTranslation } from "react-i18next";
import { AVAILABLE_LANGUAGES } from "../../services/exports/Constants";

export default ({
  roundedHeaderButton = false,
  logoLink,
  links,
  className,
  collapseBreakpointClass = "lg",
  hideLoginButton = false,
  hideRegistrationButton = false,
}) => {
  const { i18n, t } = useTranslation();
  const TRANSLATION_PATH = "Components:Headers:Dark";
  const Header1 = t(TRANSLATION_PATH + ":headers:header_1");
  const Header3 = t(TRANSLATION_PATH + ":headers:header_3");
  const Header4 = t(TRANSLATION_PATH + ":headers:header_4");
  const ButtonTitle1 = t(TRANSLATION_PATH + ":button:button_1.title");
  const ButtonTitle2 = t(TRANSLATION_PATH + ":button:button_2.title");

  function updateLanguage(language) {
    try {
      i18n.changeLanguage(language);
    } catch (error) {}
  }

  function navigateToRegister() {
    const domain = BackendApiClient.getDashboardDomain();
    return domain + `/register:lng?=${i18n.language}`;
  }

  function navigateToLogin() {
    const domain = BackendApiClient.getDashboardDomain();
    return domain + `/login:lng?=${i18n.language}`;
  }

  const defaultLinks = [
    <NavLinks>
      <NavLink href="/">{Header1}</NavLink>
      {/* <NavLink href="/faq">{Header4}</NavLink> */}
      <NavLink href="/contact">{Header3}</NavLink>
      {!hideLoginButton ? (
        <NavLink href={navigateToLogin()}>{ButtonTitle1}</NavLink>
      ) : null}
      {renderLanguages(false)}
    </NavLinks>,
  ];

  const MobileLinks = [
    <MobileLinksContainer>
      <MobileNavLink href="/">{Header1}</MobileNavLink>
      {/* <MobileNavLink href="/faq">{Header4}</MobileNavLink> */}
      <MobileNavLink href="/contact">{Header3}</MobileNavLink>
      {renderLanguages(true)}
      <SidebarButtonWrapper>
        <SecondaryLink
          css={roundedHeaderButton && tw`rounded-full`}
          href={navigateToLogin()}
        >
          {ButtonTitle1}
        </SecondaryLink>
      </SidebarButtonWrapper>
    </MobileLinksContainer>,
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/">
      <Logo width={"70"} />
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  function renderLanguages(lightVersion) {
    let currentLanguage = i18n.language;
    const GERMAN = AVAILABLE_LANGUAGES[0]?.language;
    const ENGLISH = AVAILABLE_LANGUAGES[1]?.language;
    return (
      <LanguageContainer light={lightVersion}>
        <Language
          language={GERMAN}
          currentLanguage={currentLanguage}
          onClick={() => updateLanguage(GERMAN)}
          light={lightVersion}
        >
          {GERMAN?.toUpperCase()}
        </Language>
        |
        <Language
          language={ENGLISH}
          currentLanguage={currentLanguage}
          onClick={() => updateLanguage(ENGLISH)}
          light={lightVersion}
        >
          {ENGLISH?.toUpperCase()}
        </Language>
      </LanguageContainer>
    );
  }

  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
      </DesktopNavLinks>

      <MobileNavLinksContainer
        css={collapseBreakpointCss.mobileNavLinksContainer}
      >
        {/* {logoLink} */}
        <MobileNavLinks
          initial={{ x: "-150%", display: "none" }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
        >
          <Sidebar>{MobileLinks}</Sidebar>
        </MobileNavLinks>
        <NavToggle
          onClick={toggleNavbar}
          className={showNavLinks ? "open" : "closed"}
        >
          {showNavLinks ? (
            <CloseIcon tw="w-6 h-6 text-white z-50" />
          ) : (
            <MenuIcon tw="w-6 h-6 text-gray-default" />
          )}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};

const Header = tw.header`
  flex justify-between items-center lg:py-2 py-3 bg-white lg:px-8 px-2 fixed z-50 w-full shadow-sm
`;

export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-200 will apply the bg-primary-200 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:mx-6 lg:my-0 text-black
  font-medium tracking-wide transition duration-300 no-underline
  pb-1 hover:border-primary-default hocus:border-b-2 hocus:text-primary-default
`;

export const MobileNavLink = tw.a`
text-lg my-2 lg:mx-6 lg:my-0 text-white
font-medium tracking-wide transition duration-300 no-underline
pb-1 hover:border-primary-default hocus:border-b-2 hocus:text-primary-default
`;

export const LanguageContainer = styled.div((props) => [
  tw`inline-block text-black`,
  props?.light && tw`text-white`,
]);

const Language = styled.button((props) => [
  tw`text-lg my-2 px-2 font-semibold transition duration-300 pb-1 text-black border-b-2 border-transparent hover:border-primary-default hocus:text-primary-default`,
  props?.language === props?.currentLanguage && tw`text-primary-default`,
  props?.light && tw`text-white`,
]);

export const PrimaryLink = tw(NavLink)`
  lg:mx-0 lg:mr-4
  px-8 py-3 rounded bg-primary-default text-white hocus:border-b-0 text-center hocus:text-white
  border-b-0
  shadow-lg
`;

export const SecondaryLink = tw(NavLink)`
  lg:mx-0 lg:mr-4
  px-8 py-3 rounded text-white border-white border-2 text-center
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex  items-center text-black font-bold border-b-0 text-xl! ml-0!`};

  img {
    ${tw`w-10 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`lg:hidden flex flex-1 z-30 py-2`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-default transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed h-full top-0 inset-x-0 text-left shadow-lg text-white`}
  ${MobileNavLink} {
    ${tw`flex flex-col`}
  }
`);

export const Sidebar = tw.div`bg-black w-9/12 h-full pt-16 px-4 absolute left-0`;

export const MobileLinksContainer = tw.div`flex-col flex h-full`;

export const SidebarButtonWrapper = tw.div`flex flex-1 flex-col justify-end`;

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center 
`;
