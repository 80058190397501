import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import appleIconImageSrc from "images/apple-icon.png";
import googlePlayIconImageSrc from "images/google-play-icon.png";
import { useTranslation } from "react-i18next";
import BackendApiClient from "services/api/BackendApiClient";

const Container = tw.div`bg-white text-white lg:px-8 px-2 lg:py-16 py-8 border-t`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const SixColumns = tw.div`flex text-center lg:text-left flex-col lg:flex-row justify-center lg:justify-between`;

const Column = tw.div`mt-6 lg:mt-0`;

const ColumnHeading = tw.h5`uppercase font-bold text-primary-default lg:text-left text-center`;

const LinkList = tw.ul`mt-2 -ml-8`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`text-sm font-medium text-gray-default no-underline border-b-2 border-transparent hocus:text-primary-default hocus:border-primary-default pb-1 transition duration-300 lg:text-left text-center`;

const SubscribeNewsletterColumn = tw(Column)`text-center lg:text-left `;
const SubscribeNewsletterContainer = tw.div``;
const SubscribeText = tw.p`mt-2 text-sm font-medium text-gray-default`;

const CopyWriteContainer = tw.div`w-full p-8 bg-white`;
const CopywrightNotice = tw.p`text-center text-xs font-medium text-gray-default`;

const DownloadAppLinksContainer = tw.div`mt-4 flex flex-col items-center sm:block`;
const DownloadLink = styled.a`
  ${tw`w-48 p-4 sm:p-4 text-lg font-bold no-underline tracking-wider rounded-md inline-flex justify-center items-center  mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-primary-default hocus:text-white hocus:bg-primary-default text-white shadow-lg hover:shadow-xl hocus:shadow-outline hocus:outline-none transition duration-300 `}
  img {
    ${tw`inline-block h-6 mr-3`}
  }
  span {
    ${tw`leading-none inline-block`}
  }
`;

const Footer = (props) => {
  const { t } = useTranslation();
  const TRANSLATION_PATH = "Components:Footers:Footer";

  function navigateToLogin() {
    const domain = BackendApiClient.getDashboardDomain();
    return domain + "/login";
  }

  function navigateToRegister() {
    const domain = BackendApiClient.getDashboardDomain();
    return domain + "/register";
  }

  return (
    <>
      <Container>
        <Content>
          <SixColumns>
            <Column>
              <ColumnHeading>
                {t(TRANSLATION_PATH + ":headers:header_2.title")}
              </ColumnHeading>
              <LinkList>
                <LinkListItem>
                  <Link href={navigateToLogin()}>
                    {t(TRANSLATION_PATH + ":headers:header_2:tabs.tab_1")}
                  </Link>
                </LinkListItem>
                <LinkListItem>
                  <Link href={navigateToRegister()}>
                    {t(TRANSLATION_PATH + ":headers:header_2:tabs.tab_2")}
                  </Link>
                </LinkListItem>
              </LinkList>
            </Column>

            <Column>
              <ColumnHeading>
                {t(TRANSLATION_PATH + ":headers:header_3.title")}
              </ColumnHeading>
              <LinkList>
                <LinkListItem>
                  <Link
                      href="/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    {t(TRANSLATION_PATH + ":headers:header_3:tabs.tab_1")}
                  </Link>
                </LinkListItem>
                <LinkListItem>
                  <Link
                      href="/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    {t(TRANSLATION_PATH + ":headers:header_3:tabs.tab_2")}
                  </Link>
                </LinkListItem>
                <LinkListItem>
                  <Link
                      href="/cookie-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    {t(TRANSLATION_PATH + ":headers:header_3:tabs.tab_4")}
                  </Link>
                </LinkListItem>
                <LinkListItem>
                  <Link
                      href="/impressum"
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    {t(TRANSLATION_PATH + ":headers:header_3:tabs.tab_3")}
                  </Link>
                </LinkListItem>
              </LinkList>
            </Column>
            <SubscribeNewsletterColumn>
              <SubscribeNewsletterContainer>
                <ColumnHeading>
                  {t(TRANSLATION_PATH + ":headers:header_4.title")}
                </ColumnHeading>
                <SubscribeText>
                  {t(TRANSLATION_PATH + ":headers:header_4.describtion")}
                </SubscribeText>
              </SubscribeNewsletterContainer>
              {/* <DownloadAppLinksContainer>
                <DownloadLink href={process.env.REACT_APP_APP_STORE_URL}>
                  <img src={appleIconImageSrc} alt="" />
                  <span>Apple Store</span>
                </DownloadLink>
                <DownloadLink href={process.env.REACT_APP_GOOGLE_PLAY_URL}>
                  <img src={googlePlayIconImageSrc} alt="" />
                  <span>Google Play</span>
                </DownloadLink>
              </DownloadAppLinksContainer> */}
            </SubscribeNewsletterColumn>
          </SixColumns>
        </Content>
      </Container>
      <CopyWriteContainer>
        {/* <LogoContainer> */}
        {/* <LogoImg src={LogoImage} /> */}
        {/* <LogoText>FoodAmigos</LogoText> */}
        {/* </LogoContainer> */}
        <CopywrightNotice>
          &copy; 2024 Foodamigos GmbH. All Rights Reserved.
        </CopywrightNotice>
        {/* <SocialLinksContainer>
      <SocialLink href="https://facebook.com">
        <FacebookIcon />
      </SocialLink>
      <SocialLink href="https://twitter.com">
        <TwitterIcon />
      </SocialLink>
      <SocialLink href="https://youtube.com">
        <YoutubeIcon />
      </SocialLink>
    </SocialLinksContainer> */}
      </CopyWriteContainer>
    </>
  );
};

export default Footer;
