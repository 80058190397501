import "slick-carousel/slick/slick.css";
import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings.js";
import { ReactComponent as ArrowLeftIcon } from "images/arrow-left-3-icon.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg";
import CreateAccountIllustration from "assets/Images/CreateAccountIllustration.png";
import RegisterComputerIllustration from "assets/Images/RegisterComputerIllustration.png";
import ClockIllustration from "assets/Images/ClockIllustration.png";
import { useTranslation } from "react-i18next";
import BackendApiClient from "services/api/BackendApiClient";

const Container = tw.div`relative bg-lightWhite py-8 lg:px-8 px-2 flex flex-col justify-center items-center`;

const Heading = tw(
  SectionHeading
)`font-black text-black text-center text-3xl sm:text-4xl lg:text-5xl leading-tight mb-4 lg:px-8 px-2`;
const HighlightedText = tw(
  Heading
)`text-white bg-primary-default px-4 py-2 rounded-tl-3xl rounded-br-3xl transform -skew-x-6`;
const SubText = tw.p`mt-4 text-center text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-default lg:px-8 px-2`;

const StepNumber = tw.div`mb-4 font-semibold text-3xl leading-none text-gray-default`;
const Title = tw.h4`leading-relaxed font-bold text-black text-xl lg:text-3xl`;
const Description = tw.div`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-black`;
const SliderContainer = tw.div`flex flex-row mx-auto w-full mt-2 bg-primary-200 rounded-lg shadow-lg`;
const TestimonialSlider = styled(Slider)`
  ${tw`w-10/12 text-center md:text-left mt-4`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center`}
  }
`;

const Testimonial = tw.div`outline-none h-full flex! flex-col`;

const TwoColumn = tw.div`flex flex-col lg:flex-row  mx-auto h-full`;
const TextColumn = styled.div(() => [
  tw`lg:w-4/12 flex-col flex justify-center mb-8 lg:mb-0 lg:pr-4`,
]);

const ImageColumn = tw.div`relative lg:pb-0 pb-4 justify-center items-center flex flex-1`;

const Image = styled.div((props) => [
  tw`shadow-lg mx-4 justify-center items-center flex`,
  props.imageRounded && tw`rounded-full shadow-none`,
]);

const ControlWrapper = tw.div`w-1/12 flex justify-center items-center`;
const ControlButton = styled.button`
  ${tw`p-1  transition duration-300 text-primary-default hover:text-white`}
  svg {
    ${tw`w-4 h-4 stroke-3`}
  }
`;

const PrimaryAction = tw.a`no-underline mt-2 px-8 py-3 text-lg sm:px-8 sm:py-4 bg-primary-default text-white font-bold rounded shadow-lg hocus:text-white transition duration-300`;

export default ({
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
}) => {
  const { t } = useTranslation();
  const TRANSLATION_PATH = "Components:Features:RestaurantGettingStartedGuide";
  const [sliderRef, setSliderRef] = useState(null);
  const heading = t(TRANSLATION_PATH + ".title");
  const headingHighlighted = t(TRANSLATION_PATH + ".title_highlighted");
  const description = t(TRANSLATION_PATH + ".description");
  const buttonTitle = t(TRANSLATION_PATH + ":button.title");
  const steps = [
    {
      id: 1,
      heading: t(TRANSLATION_PATH + ":steps.steps_1.title"),
      illustration: CreateAccountIllustration,
      style: { width: "100%" },
    },
    {
      id: 2,
      heading: t(TRANSLATION_PATH + ":steps.steps_2.title"),
      illustration: RegisterComputerIllustration,
      style: { width: "100%" },
    },
    {
      id: 3,
      heading: t(TRANSLATION_PATH + ":steps.steps_3.title"),
      illustration: ClockIllustration,
      style: { width: "70%" },
    },
  ];

  function getStarted() {
    const domain = BackendApiClient.getDashboardDomain();
    return domain + "/register";
  }

  return (
    <Container>
      <Heading>{heading}</Heading>
      <HighlightedText>{headingHighlighted}</HighlightedText>
      <SubText>{description}</SubText>

      <SliderContainer>
        <ControlWrapper>
          <ControlButton onClick={sliderRef?.slickPrev}>
            <ArrowLeftIcon />
          </ControlButton>
        </ControlWrapper>

        <TestimonialSlider arrows={false} ref={setSliderRef}>
          {steps.map((step, index) => (
            <Testimonial key={index}>
              <TwoColumn>
                <TextColumn>
                  <StepNumber>
                    {(index + 1).toString().padStart(2, "0")}
                  </StepNumber>
                  <Title>{step?.heading}</Title>
                  <Description>{step?.description}</Description>
                  <div>
                    <PrimaryAction href={getStarted()}>
                      {buttonTitle}
                    </PrimaryAction>
                  </div>
                </TextColumn>

                <ImageColumn>
                  <Image
                    style={step?.style}
                    src={step?.illustration}
                    imageRounded={step?.id === 3}
                  >
                    <img
                      src={step?.illustration}
                      alt={step?.illustration}
                      style={step?.style}
                    />
                  </Image>
                </ImageColumn>
              </TwoColumn>
            </Testimonial>
          ))}
        </TestimonialSlider>
        <ControlWrapper>
          <ControlButton onClick={sliderRef?.slickNext}>
            <ArrowRightIcon />
          </ControlButton>
        </ControlWrapper>
      </SliderContainer>
    </Container>
  );
};
