class HelperMethods {
  /**
   * Format a price to match 1.000,00;
   * @function formatCurrency
   * @param {price}  - a number that will be formated
   */
  formatCurrency(price) {
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    }).format(price);
  }
}

export default new HelperMethods();
