import * as React from "react";

const EmailIllustration = ({ width, style }) => {
  function buildXml() {
    return (
      <svg
        width={width ? width : "903"}
        height={width ? width * 0.72 : "656"}
        viewBox="0 0 903 656"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M526.364 135.436C468.835 133.78 414.045 118.312 361.762 101.387C309.478 84.4619 257.576 65.7422 201.425 56.7768C165.312 51.0108 124.014 50.1958 94.9159 66.3144C66.9099 81.8261 57.8664 108.61 53.0022 133.468C49.3346 152.162 47.1843 171.844 57.2162 189.341C64.1526 201.48 76.5429 211.703 85.0921 223.339C114.832 263.839 93.8147 313.773 61.5775 353.311C46.456 371.857 28.9154 389.554 17.2448 409.297C5.57415 429.04 0.198379 451.679 10.3863 471.794C20.5049 491.737 44.6178 506.746 70.7423 517.298C123.798 538.714 186.321 544.844 247.284 548.321C382.233 556.003 517.91 552.656 653.224 549.344C703.296 548.113 753.594 546.864 802.861 540.413C830.216 536.833 858.456 531.153 878.295 517.436C903.509 500.026 909.752 470.537 892.861 448.705C864.526 412.08 786.213 402.985 766.366 363.681C755.459 342.048 766.661 317.944 782.502 297.88C816.491 254.839 873.465 217.079 876.465 167.882C878.52 134.066 851.138 100.251 808.791 84.2625C764.407 67.5023 702.854 69.6092 670.062 97.3551C636.411 125.916 577.157 136.936 526.364 135.436Z"
          fill="#F5F5F5"
        />
        <path
          d="M661.3 128.43C661.499 128.43 661.66 128.269 661.66 128.07C661.66 127.871 661.499 127.71 661.3 127.71C661.102 127.71 660.94 127.871 660.94 128.07C660.94 128.269 661.102 128.43 661.3 128.43Z"
          fill="#E6E8EC"
        />
        <path
          d="M471.549 6.39L210.039 176.05L380.699 483.85C381.496 485.289 382.583 486.548 383.892 487.546C385.2 488.544 386.7 489.261 388.299 489.65L542.929 527.37C545.579 528.011 548.368 527.724 550.832 526.558C553.296 525.391 555.285 523.416 556.469 520.96L633.169 361.61L717.229 249.24L517.749 13.24C512.198 6.67584 504.445 2.35944 495.941 1.09861C487.438 -0.162226 478.766 1.71892 471.549 6.39V6.39Z"
          fill="#DCDFED"
        />
        <path
          opacity="0.1"
          d="M719.889 250.57L719.149 251.57L669.909 317.4L669.019 318.59L635.829 362.96L570.229 499.27L568.389 503.08L559.129 522.31C557.945 524.77 555.952 526.748 553.484 527.915C551.016 529.082 548.222 529.366 545.569 528.72L390.939 490.98C389.34 490.592 387.839 489.876 386.531 488.878C385.223 487.879 384.136 486.62 383.339 485.18L368.469 458.41L365.799 453.59L258.469 259.93L257.549 258.28L212.689 177.38L218.109 173.86L259.199 147.21L260.149 146.59L346.679 90.45L352.139 86.91L474.189 7.72C481.406 3.04004 490.082 1.15279 498.591 2.41199C507.1 3.67119 514.857 7.9904 520.409 14.56L611.409 122.26L615.409 127.06L681.459 205.2L681.819 205.63L715.029 244.91L719.209 249.86L719.889 250.57Z"
          fill="#D1D7DF"
        />
        <path
          opacity="0.1"
          d="M679.26 204.3L671.2 260.3L668.77 277.2L667.38 313.2L667.27 316.01L660.04 504.11C659.97 505.855 659.518 507.564 658.716 509.115C657.914 510.666 656.781 512.022 655.397 513.088C654.013 514.153 652.412 514.902 650.707 515.28C649.002 515.659 647.235 515.659 645.53 515.28L567.59 497.88L566.75 497.69L366.86 453.08L363.19 452.26L253.3 427.72L255.82 258.6L255.9 252.95L256.66 201.76L257.5 145.26L344.03 89.12L349.49 85.58C429.75 93.81 536.49 109.64 608.8 120.93L612.8 125.73L678.8 203.87L679.26 204.3Z"
          fill="black"
        />
        <path
          d="M688.39 137.9L678.9 203.9L670.2 264.34L667.77 281.2L666.38 317.2L659.04 508.11C658.97 509.855 658.518 511.564 657.716 513.115C656.914 514.666 655.781 516.022 654.397 517.088C653.013 518.153 651.412 518.902 649.707 519.28C648.002 519.659 646.235 519.659 644.53 519.28L565.75 501.69L365.86 457.08L252.3 431.72L254.9 256.95L255.66 205.76L256.55 145.88L257.46 84.29C276.24 83.4 307.14 85.41 344.03 89.12C426.63 97.41 539.23 114.18 612.85 125.73C657.93 132.8 688.39 137.9 688.39 137.9Z"
          fill="#69CE8C"
        />
        <path
          opacity="0.1"
          d="M712.471 243.58L670.201 264.31L454.471 370.11L255.661 205.8L215.471 172.53L210.051 176.05L254.911 256.95L252.311 431.72L365.871 457.08L380.711 483.85C381.507 485.29 382.594 486.549 383.902 487.547C385.21 488.546 386.712 489.262 388.311 489.65L542.941 527.37C545.589 528.008 548.376 527.72 550.838 526.554C553.299 525.388 555.287 523.414 556.471 520.96L565.731 501.73L644.511 519.32C646.216 519.699 647.983 519.699 649.688 519.32C651.393 518.942 652.994 518.193 654.378 517.128C655.762 516.062 656.895 514.706 657.697 513.155C658.499 511.604 658.951 509.895 659.021 508.15L666.361 317.24L716.491 250.24L716.631 248.55L712.471 243.58Z"
          fill="black"
        />
        <path
          d="M210.04 176.05L192.21 481.38C192.045 484.206 192.891 486.998 194.597 489.258C196.302 491.517 198.757 493.095 201.52 493.71L675.04 598.86C676.7 599.224 678.419 599.228 680.081 598.873C681.743 598.517 683.31 597.811 684.677 596.8C686.043 595.79 687.178 594.499 688.005 593.014C688.831 591.529 689.331 589.884 689.47 588.19L717.22 249.24L454.47 378.11L210.04 176.05Z"
          fill="#D1D7DF"
        />
        <path
          opacity="0.1"
          d="M195.47 488.37L441.47 336.6C445.113 334.391 449.419 333.541 453.627 334.2C457.836 334.858 461.677 336.984 464.47 340.2L684.65 596.99C685.45 597.91 685.84 597.25 684.65 596.99L195.47 488.37C194.25 489.1 194.07 488.06 195.47 488.37Z"
          fill="black"
        />
        <path
          d="M197.229 488.12L440.689 341.05C444.538 338.725 449.084 337.834 453.526 338.533C457.968 339.231 462.02 341.476 464.969 344.87L683.159 595.8C683.521 596.218 683.741 596.738 683.79 597.288C683.839 597.838 683.713 598.39 683.431 598.864C683.149 599.339 682.725 599.713 682.218 599.933C681.712 600.153 681.149 600.208 680.609 600.09L198.019 492.93C197.505 492.819 197.037 492.556 196.675 492.175C196.312 491.794 196.073 491.313 195.988 490.794C195.903 490.275 195.975 489.743 196.197 489.266C196.418 488.789 196.778 488.39 197.229 488.12V488.12Z"
          fill="#D1D7DF"
        />
        <path
          d="M426.449 655.61C587.026 655.61 717.199 642.483 717.199 626.29C717.199 610.097 587.026 596.97 426.449 596.97C265.872 596.97 135.699 610.097 135.699 626.29C135.699 642.483 265.872 655.61 426.449 655.61Z"
          fill="#EFEDED"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.93786 385.736C28.6043 417.052 50.3449 447.374 63.4008 481.613C62.8747 477.577 62.3168 473.545 61.6924 469.52C58.3797 448.179 54.2624 426.994 49.9359 405.835C47.7326 395.062 45.4023 384.327 43.0123 373.594C41.7268 367.829 40.4572 362.061 39.1452 356.302C38.8275 354.906 38.5544 352.469 38.1566 350C50.8449 391.637 63.0807 433.305 72.0308 475.942C73.2049 481.538 74.2048 487.124 75.0238 492.7C75.304 491.428 75.5796 490.156 75.8812 488.887C79.5026 473.68 84.9347 458.594 91.4027 444.374C94.4947 437.575 98.0464 430.924 102.228 424.726C104.836 420.863 110.986 416.271 113.364 412.314C100.355 436.176 95.657 466.032 88.1451 491.973C86.3116 498.304 84.4667 504.624 82.6591 510.955C93.9873 488.237 111.563 469.571 136 461.483C106.42 481.027 88.678 515.254 76.7221 550.26C76.6827 550.675 76.6359 551.09 76.5954 551.506C80.1136 544.829 84.5452 538.789 90.5515 534.56C103.407 525.504 117.667 528.142 130.137 535.91C120.35 529.689 102.663 535.354 94.428 541.677C88.5329 546.203 83.4948 552.275 79.1198 558.2C78.0715 559.62 76.6413 561.277 75.1304 563.044C73.012 576.795 69.5599 590.452 64.6725 604C65.479 600.111 64.8959 593.892 65.1195 590.993C65.1439 590.679 65.1636 590.365 65.1878 590.053C64.4448 593.019 63.7271 595.951 63.036 598.837C64.4098 593.331 65.1564 587.583 65.7392 581.776C65.8894 579.281 66.0357 576.785 66.1905 574.29C66.1414 569.553 65.9464 564.799 65.6164 560.036C64.4466 555.408 63.1367 551.042 62.0565 547.411C58.1585 534.31 52.4442 521.76 45.4108 510.051C38.9937 499.366 31.3124 489.688 22.4448 480.935C17.6643 476.217 12.6614 471.725 7.72888 467.17C5.94526 465.523 2.75722 461.474 0 459.363C20.0805 470.434 40.7489 482.41 55.3976 500.248C53.6802 493.653 51.8149 487.151 49.8417 480.78C39.8311 448.454 27.1913 414.953 9.93786 385.736Z"
          fill="#D1D7DF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M30 563C30 598.899 32.8428 628 71 628C109.157 628 112 598.899 112 563"
          fill="#74BE75"
        />
      </svg>
    );
  }

  return buildXml();
};

export default EmailIllustration;
