export default {
  title: "Gastronomie",
  title_highlighted: "Umsatzbeschleuniger",
  description: "Die Nr. 1 Lösung zur Umsatzsteigerung für lokale Restaurants.",
  illustration_explainer: {
    title:
      "Maximiere deinen Umsatz",
    description:
      "Erhalte die Möglichkeiten, die nur die großen Ketten haben.",
  },
  button: {
    title: "Kostenlos Registrieren",
  },
};
