export default {
  title: "Keep your focus on serving ",
  title_highlighted: "great food",
  description: "We take care of the rest",
  items: {
    item_1: {
      title: "Save 1000s of EUR",
      description:
        "Proven system to increase your profitability by 20% per month.",
    },
    item_2: {
      title: "We care about your success",
      description:
        "24x7 customer service because we are here for you.",
    },
    item_3: {
      title: "Increase loyal customers",
      description:
        "Turn your customers into regulars and increase the frequency of purchases.",
    },
  },
};
