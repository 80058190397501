import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import RestaurantIntro from "components/hero/RestaurantIntro.js";
import Footer from "components/footers/Footer.js";
import RestaurantGettingStartedGuide from "components/features/RestaurantGettingStartedGuide.js";
import RestaurantServices from "components/features/RestaurantServices";
import RestaurantBenefits from "components/features/RestaurantBenefits";
import RestaurantSalesCalculator from "components/features/RestaurantSalesCalculator.js";
import Header from "../components/headers/dark.js";
import EnableCookies from "components/cta/EnableCookies.js";
import GetInTouch from "components/cta/GetInTouch.js";

export const Wrapper = tw.div`pt-16 bg-lightWhite`;

export default () => {
  return (
    <>
      <Header />
      <Wrapper>
        <AnimationRevealPage>
          <RestaurantIntro />
          <RestaurantBenefits />
          <RestaurantSalesCalculator />
          {/* <RestaurantServices /> */}
          {/* <RestaurantGettingStartedGuide /> */}
          <GetInTouch />
          <Footer />
        </AnimationRevealPage>
      </Wrapper>
      <EnableCookies />
    </>
  );
};
