import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import NoContractsIcon from "../../images/no-contracts-icon.svg";
import NoPaymentIcon from "../../images/no-payment-icon.svg";
import NoRiskIcon from "../../images/no-risk-icon.svg";
import { useTranslation } from "react-i18next";

const Container = tw.div`relative bg-lightWhite py-8 lg:px-8 px-2 flex flex-col justify-center items-center`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto `}
`;
const Heading = tw(
  SectionHeading
)`font-black text-center text-3xl sm:text-4xl lg:text-5xl leading-tight`;
const HighlightedText = tw(
  Heading
)`text-white bg-primary-default px-4 py-2 rounded-tl-3xl rounded-br-3xl transform -skew-x-6`;
const Description = tw.p`mt-4 w-full text-center text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-default`;
const FeatureHeading = tw.div`font-bold text-lg text-black text-center`;
const FeatureDescription = tw.div`mt-1 text-sm text-gray-default text-center`;

const Content = tw.div`lg:flex-row flex-col flex lg:mt-4 mt-0`;
const Column = styled.div`
  ${tw`flex-1 lg:mt-0 mt-4 bg-white py-4 rounded-lg lg:mx-4 justify-center items-center shadow-xl `}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center mt-2 p-4`}
  .imageContainer {
    ${tw`border-2 border-primary-200 text-center rounded-full p-4 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-200`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  const { t } = useTranslation();
  const TRANSLATION_PATH = "Components:Features:RestaurantServices";

  const heading = t(TRANSLATION_PATH + ".title");
  const headingHighlighted = t(TRANSLATION_PATH + ".title_highlighted");
  const description = t(TRANSLATION_PATH + ".description");

  const cards = [
    {
      imageSrc: NoPaymentIcon,
      title: t(TRANSLATION_PATH + ":steps:steps_1.title"),
      description: t(TRANSLATION_PATH + ":steps:steps_1.description"),
    },
    {
      imageSrc: NoRiskIcon,
      title: t(TRANSLATION_PATH + ":steps:steps_2.title"),
      description: t(TRANSLATION_PATH + ":steps:steps_2.description"),
    },
    {
      imageSrc: NoContractsIcon,
      title: t(TRANSLATION_PATH + ":steps:steps_3.title"),
      description: t(TRANSLATION_PATH + ":steps:steps_3.description"),
    },
  ];

  return (
    <Container>
      <Heading>{heading}</Heading>
      <HighlightedText>{headingHighlighted}</HighlightedText>
      <Description>{description}</Description>
      <Content>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <FeatureHeading>{card.title}</FeatureHeading>
                <FeatureDescription>{card?.description}</FeatureDescription>
              </span>
            </Card>
          </Column>
        ))}
      </Content>
    </Container>
  );
};
