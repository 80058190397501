import BackendApiClient from "./BackendApiClient";

class SupportTicketsManager {
  create = (data) => {
    return BackendApiClient.requestAsync({
      method: 'POST',
      url: '/public/support-tickets',
      data,
    });
  };
}

export default new SupportTicketsManager();
