import * as React from "react";

const DashboardIcon = ({ width, color, style }) => {
  function buildXml() {
    return (
      <svg
        width={width ? width : "90"}
        height={width ? width * 1.17 : "106"}
        viewBox="0 0 90 106"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
      >
        <line
          x1="3.5"
          y1="3.5"
          x2="3.5"
          y2="101.5"
          stroke={color ? color : "#9A9A9A"}
          stroke-width="7"
          stroke-linecap="round"
        />
        <line
          x1="3.5"
          y1="102.5"
          x2="86.5"
          y2="102.5"
          stroke={color ? color : "#9A9A9A"}
          stroke-width="7"
          stroke-linecap="round"
        />
        <mask id="path-3-inside-1_637:833" fill="white">
          <rect x="17" y="26" width="27" height="65" rx="4" />
        </mask>
        <rect
          x="17"
          y="26"
          width="27"
          height="65"
          rx="4"
          stroke={color ? color : "#9A9A9A"}
          stroke-width="14"
          mask="url(#path-3-inside-1_637:833)"
        />
        <mask id="path-4-inside-2_637:833" fill="white">
          <rect x="57" width="27" height="91" rx="4" />
        </mask>
        <rect
          x="57"
          width="27"
          height="91"
          rx="4"
          stroke={color ? color : "#9A9A9A"}
          stroke-width="14"
          mask="url(#path-4-inside-2_637:833)"
        />
      </svg>
    );
  }

  return buildXml();
};

export default DashboardIcon;
