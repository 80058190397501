export default {
  title: "Mach was du am ",
  title_highlighted: "besten kannst...",
  description: "Wir kümmern uns um den Rest",
  items: {
    item_1: {
      title: "Spare tausende von EUR",
      description:
        "Bewährtes System zur Steigerung deines Profits um 20 % pro Monat.",
    },
    item_2: {
      title: "Dein Erfolg liegt uns am Herzen",
      description:
        "24x7 Kundenservice, denn wir sind für dich da.",
    },
    item_3: {
      title: "Mehr Stammkunden",
      description:
        "Verwandeln Sie Kunden zu Stammkunden und steigern Sie die Kauffrequenz.",
    },
  },
};
