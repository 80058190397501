import react from "react";
import { CAlert } from "@coreui/react";

export default function FeedbackLabel({
  message = "message",
  errors = {},
  type = "success" | "error",
}) {
  function renderSuccesLabel() {
    return <CAlert color="success">{message}</CAlert>;
  }

  function renderErrorLabel() {
    return (
      <CAlert color="danger">
        <p className={!errors ? "mb-0" : ""}>{message}</p>
        {errors &&
          Object.values(errors)?.map((error) =>
            error ? (
              <div>
                <p>• {error}</p>
              </div>
            ) : null
          )}
      </CAlert>
    );
  }

  function renderLabel() {
    switch (type) {
      case "error":
        return renderErrorLabel();
      default:
        return renderSuccesLabel();
    }
  }

  return renderLabel();
}
